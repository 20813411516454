<template>
  <div>
    <div class="body">
      <el-card>
        <h3 slot="header">{{$t('我的单词本')}}</h3>
        <el-row :gutter="30" class="margin-t padding top">
          <el-col :span="8" v-for="item in $store.getters.getBooks(-1)" :key="item.id">
            <el-card>
              <div class="flex flex-v-center padding">
                <el-image class="l-img br" style="width:100px;height:100px" fit="contain" :src="$host + $url(item,'logo')">
                </el-image>
                <div class="margin-x flex1">
                  <h4 class="ellipsis">{{ $t1(item,'title') }}</h4>
                  <div class="st">{{$t('已掌握')}} {{ item.record_count }}/{{ item.words_count }}</div>
                  <div class="right" style="margin-top:10px">
                    <el-link @click="to(item.tag)" class="st1">{{$t('开始背单词')}}<i class="el-icon-arrow-right"></i></el-link>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>

          <el-col class="book-item" :span="8" v-for="item in $store.state.mineBooks" :key="item.id">
            <el-card>
              <div class="flex flex-v-center padding" style="height:100px">

                <div class="margin-x flex1">
                  <div class="flex flex-between flex-v-center">
                    <h4 class="ellipsis">{{ $t1(item,'title') }}</h4>

                    <el-tooltip placement="bottom">
                      <div slot="content">
                        <div class="pointer"> <a @click="add(item)" class="color-white ul-hover ">{{$t('重命名')}}</a></div>
                        <div class="margin-t pointer"> <a class="color-white ul-hover" @click="remove(item)">{{$t('删 除')}}</a>
                        </div>
                      </div>
                      <i class="el-icon-more pointer" style="font-size:30px;color:gray"></i>
                    </el-tooltip>

                  </div>

                  <div class="st">{{$t('已掌握')}} {{ item.record_count }}/{{ item.words_count }}</div>
                  <div class="right" style="margin-top:10px">
                    <el-link @click="to('mine', item.id)" class="st1">{{$t('开始背单词')}}<i class="el-icon-arrow-right"></i></el-link>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>

          <el-col :span="8" class="book-item">
            <el-card class="pointer">
              <div class="flex flex-h-center padding" @click="add">
                <i class="el-icon-plus" style="font-size:100px;color:#ccc;"></i>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-card>

      <el-divider></el-divider>

      <el-card v-if="$store.state.mode == 'PTE'">
        <div slot="header" class="flex flex-v-center flex-between">
          <h3>{{$t('PTE单词库')}}</h3>
          <div class="flex flex-v-center">
            <h4 class="margin-x">{{$t('总单词数')}}：{{ $store.state.wordData[0] }}</h4><el-divider direction="vertical"></el-divider>
            <h4 class="margin-x">{{$t('总短语数')}}：{{ $store.state.wordData[1] }}</h4>
          </div>
        </div>


        <div v-for="(sort, index0) in $store.state.bookSorts" :key="index0" class="padding">
          <h3>{{ $t1(sort,'title') }}</h3>
          <el-row :gutter="30" class="margin-t">
            <el-col :span="8" v-for="(item, index) in sort.types" :key="index">
              <el-card :class="{ 'margin-lt': index > 2}">
                <div class="flex flex-v-center padding">
                  <el-image class="l-img br" style="width:100px;height:100px" fit="contain" :src="$host + $url(item,'logo')">
                  </el-image>
                  <div class="margin-x flex1">
                    <h4 class="ellipsis">{{ $t1(item,'title') }}</h4>
                    <div class="st">{{$t('已掌握')}} {{ item.record_count }}/{{ item.words_count }}</div>
                    <div class="right" style="margin-top:10px">
                      <el-link @click="to(item.tag)" class="st1">{{$t('开始背单词')}}<i class="el-icon-arrow-right"></i></el-link>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-card>



      <el-card v-if="$store.state.mode == 'CCL'">
        <div slot="header" class="flex flex-v-center flex-between">
          <h3>{{$t('CCL单词库')}}</h3>
          <div class="flex flex-v-center">
            <h4 class="margin-x">{{$t('总单词数')}}：{{ $store.state.wordData[2] || 0 }}</h4>
          </div>
        </div>

        <el-row :gutter="30" class="margin-t padding">
          <el-col :span="8" v-for="(item, index) in $store.getters.getBooks(1)" :key="index">
            <el-card class="margin-lb">
              <div class="flex flex-v-center padding">
                <el-image class="l-img br" style="width:100px;height:100px;flex-shrink: 0;" fit="contain"
                  :src="$host + $url(item,'logo')">
                </el-image>
                <div class="margin-x flex1 ellipsis">
                  <h4 class="ellipsis">{{ item.title }}</h4>
                  <div class="st">{{$t('已掌握')}} {{ item.record_count }}/{{ item.words_count }}</div>
                  <div class="right" style="margin-top:10px">
                    <el-link @click="to(item.tag)" class="st1">{{$t('开始背单词')}}<i class="el-icon-arrow-right"></i></el-link>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-card>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },

  created() {
    this.$store.dispatch("fetch_mine_books", true);
    this.$store.dispatch("fetch_book_sorts", true);
    this.$store.dispatch("fetch_books", true);
    this.$store.dispatch("fetch_word_data", true);
  },

  methods: {
    to(tag, id) {
      if (id) {
        this.$router.push("/main/vocabulary?tag=" + tag + "&book_id=" + id)
      } else {
        this.$router.push("/main/vocabulary?tag=" + tag)
      }
    },
    remove(item) {
      this.$confirm('', this.$t('确认删除此单词库') +'?', {
        showCancle: false,
        center: true,
        confirmButtonClass: "confirm-btn",
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'primary'
      }).then(() => {
        this.$http.post("/api/book/remove", { id: item.id }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('删除成功')+'!'
          });
          this.$store.dispatch("fetch_mine_books", true);
        })
      }).catch(() => {

      });
    },
    add(item) {
      if (this.$store.getters.getRole() > 0) {
        this.$prompt('', this.$t('新单词库'), {
          inputValue: item ? item.title : '',
          center: true,
          roundButton: true,
          confirmButtonText: this.$t('确定'),
          cancelButtonText: this.$t('取消'),
        }).then(({ value }) => {
          if (value) {
            this.$http.post("/api/book/save", { id: item ? item.id : 0, title: value }).then(() => {
              this.$message({
                type: 'success',
                message: this.$t("操作成功")+"!"
              });
              this.$store.dispatch("fetch_mine_books", true);
            })
          }
        }).catch(() => {

        });
      } else {
        this.$vip_alert("添加单词本");
      }
    }
  },
};
</script>

<style scoped>
.header {
  padding: 0 28px;
  background-image: linear-gradient(0deg, #1ad0b3, #23ccd1), linear-gradient(#fff, #fff);
  border-radius: 10px;
  color: #fff;
  z-index: 100;
}


.body>>>.el-card__body {
  padding: 0;
}

.l-img {
  width: 200px;
  height: 160px;
}

.top .book-item:nth-child(n+4) {
  margin-top: 15px;
}
</style>
